export default [
  {
    title: 'n_dash',
    path: '/',
    icon: 'star',
    roles: 'admin',
    isDefault: true,
    isEnabled: true
  },
  {
    title: 'n_tasks',
    path: '/tasks',
    icon: 'file-lines',
    roles: 'admin|editor',
    isDefault: true,
    isEnabled: true
  },
  {
    title: 'n_customers',
    path: '/customers',
    icon: 'users',
    roles: 'admin|editor',
    isEnabled: true
  },
  {
    title: 'n_notes_all',
    path: '/notes',
    icon: 'note-sticky',
    roles: 'admin|editor',
    isEnabled: true,
    childs: [
      {
        title: 'n_notes_public',
        path: '/notes/public',
        icon: 'eye',
        roles: 'admin|editor',
        isEnabled: true
      },
      {
        title: 'n_notes_private',
        path: '/notes/private',
        icon: 'eye-slash',
        roles: 'private',
        isEnabled: true
      }
    ]
  },
  {
    title: 'n_talks',
    path: '/talks',
    icon: 'people-arrows',
    roles: 'admin|editor',
    isEnabled: true
  },
  {
    title: 'n_messages',
    path: '/messages',
    icon: 'comments',
    roles: 'admin|editor',
    isEnabled: true
  },
  // {
  //   title: 'n_faq',
  //   path: '/faqs',
  //   icon: 'circle-question',
  //   roles: 'admin|editor',
  //   isEnabled: true
  // },
  {
    title: 'n_tec',
    path: '/tec/it',
    icon: 'triangle-exclamation',
    roles: 'admin|editor',
    isEnabled: true
  },
  {
    title: 'n_affiliates',
    path: '/affiliates',
    icon: 'diagram-project',
    roles: 'admin|editor',
    isEnabled: true
  },
  {
    title: 'n_reports_title',
    path: '/reports',
    icon: 'print',
    roles: 'admin|editor',
    isEnabled: true,
    childs: [
      {
        title: 'n_reports_users',
        path: '/reports/users',
        icon: 'users',
        roles: 'admin',
        isEnabled: true
      },
      {
        title: 'n_reports_hostess',
        path: '/reports/hostess',
        icon: 'person-dress',
        roles: 'admin',
        isEnabled: true
      },
      {
        title: 'n_reports_hostessChat',
        path: '/reports/hostess-chat',
        icon: 'comments',
        roles: 'admin|editor',
        isEnabled: true
      },
      {
        title: 'n_reports_cities',
        path: '/reports/cities',
        icon: 'location-dot',
        roles: 'admin',
        isEnabled: true
      },
      {
        title: 'n_reports_payments',
        path: '/reports/payments',
        icon: 'euro-sign',
        roles: 'admin',
        isEnabled: true
      }
    ]
  },
  {
    title: 'n_users',
    path: '/users',
    icon: 'user-shield',
    roles: 'admin',
    isEnabled: true
  },
  {
    title: 'n_tools',
    path: '/tools',
    icon: 'wrench',
    roles: 'admin|editor',
    isEnabled: true,
    childs: [
      {
        title: 'n_tools_antiscam',
        path: '/tools/anti-scam',
        icon: 'ban',
        roles: 'admin|editor',
        isEnabled: true
      }
    ]
  },
  {
    title: 'n_settings',
    path: '/settings',
    icon: 'gear',
    roles: 'admin',
    isEnabled: true
  }
]
