import Vue from 'vue'

// use external lightbox component
import LightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

import LineChart from '~/components/elements/charts/LineChart'
import ChatTalk from '~/components/elements/chat/ChatTalk'
import ChatTalks from '~/components/elements/chat/ChatTalks'
import DataFastAdd from '~/components/elements/datatable/DataFastAdd'
import DataFastEdit from '~/components/elements/datatable/DataFastEdit'
import DataFilter from '~/components/elements/datatable/DataFilter'
import DataFilterVertical from '~/components/elements/datatable/DataFilterVertical'
import DataFilterInline from '~/components/elements/datatable/DataFilterInline'
import DataSearch from '~/components/elements/datatable/DataSearch'
import DataTable from '~/components/elements/datatable/DataTable'
import DataCards from '~/components/elements/datatable/DataCards'
import DataLayout from '~/components/elements/datatable/DataLayout'
import DataSort from '~/components/elements/datatable/DataSort'
import DataLayoutSwitcher from '~/components/elements/datatable/DataLayoutSwitcher'
import Pagination from '~/components/elements/datatable/Pagination'
import PaginationPageSize from '~/components/elements/datatable/PaginationPageSize'
import ItemList from '~/components/elements/doc/ItemList'
import ItemForm from '~/components/elements/doc/ItemForm'
import GlobalForms from '~/components/elements/doc/GlobalForms'
import AppState from '~/components/elements/doc/AppState'
import AffiliateLink from '~/components/elements/AffiliateLink'
import LocaleSwitcher from '~/components/elements/LocaleSwitcher'
import UserHeaderNav from '~/components/elements/UserHeaderNav'
import UserProfileItem from '~/components/elements/UserProfileItem'
import UserProfileBtn from '~/components/elements/UserProfileBtn'
import UserProfileLink from '~/components/elements/UserProfileLink'
import VSelect from '~/components/elements/VSelect'
import ModalAvatar from '~/components/elements/ModalAvatar'
import Multiselect from '~/components/elements/Multiselect'
import Spinner from '~/components/elements/Spinner'
import TalkBtn from '~/components/elements/TalkBtn'
import TalkLink from '~/components/elements/TalkLink'
import TaskActions from '~/components/elements/TaskActions'
import CustomersOnline from '~/components/elements/CustomersOnline'
import MediaTransform from '~/components/elements/MediaTransform'
import DebugInfoCollapse from '~/components/elements/DebugInfoCollapse'
import AutoComplete from '~/components/elements/inputs/AutoComplete'
import DateRange from '~/components/elements/inputs/DateRange'
import NumRange from '~/components/elements/inputs/NumRange'
import { MonthPicker, MonthPickerInput } from '~/components/elements/inputs/month-picker-input'
import MobileNav from '~/components/elements/mobile-nav/MobileNav'
import MobileNavTrigger from '~/components/elements/mobile-nav/MobileNavTrigger'
import MobileChatTrigger from '~/components/elements/mobile-nav/MobileChatTrigger'
import MarketFlag from '~/components/elements/MarketFlag'
import Sidebar from '~/components/elements/sidebar/Sidebar'
import SidebarNav from '~/components/elements/sidebar/SidebarNav'
import SidebarNavItem from '~/components/elements/sidebar/SidebarNavItem'
import Timeline from '~/components/elements/Timeline'
import NotFound from '~/components/elements/doc/NotFound'
import Errors from '~/components/elements/doc/Errors'
import AffiliateEditForm from '~/components/forms/AffiliateEditForm'
import AffiliateCustomers from '~/components/forms/AffiliateEditForm/AffiliateCustomers'
import AffiliateTestForm from '~/components/forms/AffiliateEditForm/AffiliateTestForm'
import AffiliateFilters from '~/components/forms/AffiliateEditForm/AffiliateFilters'
import CustomerEditForm from '~/components/forms/CustomerEditForm'
import SettingsForm from '~/components/forms/SettingsForm'
import TalkPreviewForm from '~/components/forms/TalkPreviewForm'
import AntiScamForm from '~/components/forms/Tools/AntiScamForm'
import CitiesReport from '~/components/reports/CitiesReport'
import HostesChatReport from '~/components/reports/HostesChatReport'
import HostesReport from '~/components/reports/HostesReport'
import UsersReport from '~/components/reports/UsersReport'
import PaymentsReport from '~/components/reports/PaymentsReport'

const components = {
  LightBox,
  ItemList,
  GlobalForms,
  AppState,
  AffiliateTestForm,
  AffiliateEditForm,
  CustomerEditForm,
  SettingsForm,
  TalkPreviewForm,
  AntiScamForm,
  MediaTransform,
  DebugInfoCollapse,
  ItemForm,
  AffiliateCustomers,
  AffiliateLink,
  AffiliateFilters,
  ChatTalk,
  ChatTalks,
  LocaleSwitcher,
  MobileNav,
  MobileNavTrigger,
  MobileChatTrigger,
  MarketFlag,
  Sidebar,
  SidebarNav,
  SidebarNavItem,
  Timeline,
  DataFastAdd,
  DataFastEdit,
  DataFilter,
  DataFilterVertical,
  DataFilterInline,
  DataSearch,
  DataTable,
  DataCards,
  DataLayout,
  DataSort,
  DataLayoutSwitcher,
  Pagination,
  PaginationPageSize,
  UserHeaderNav,
  UserProfileItem,
  UserProfileBtn,
  UserProfileLink,
  VSelect,
  AutoComplete,
  DateRange,
  NumRange,
  MonthPicker,
  MonthPickerInput,
  ModalAvatar,
  Multiselect,
  Spinner,
  TalkBtn,
  TalkLink,
  TaskActions,
  LineChart,
  CustomersOnline,
  CitiesReport,
  HostesChatReport,
  HostesReport,
  UsersReport,
  PaymentsReport,
  NotFound,
  Errors
}

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})
