import { getLog, clearLog, clearTabLog, getTabKeys, log, warn, error, info } from '~/mixins/helpers'

export default (ctx, inject) => {
  inject('getTabKeys', function () {
    if (ctx.$config.appDebug) {
      return getTabKeys()
    }
  })
  inject('getLog', function (/* { tabId } */ props) {
    if (ctx.$config.appDebug) {
      return getLog(props)
    }
  })
  inject('clearLog', function () {
    if (ctx.$config.appDebug) {
      return clearLog()
    }
  })
  inject('clearTabLog', function (tabKey) {
    if (ctx.$config.appDebug) {
      return clearTabLog(tabKey)
    }
  })

  inject('log', function (...args) {
    if (ctx.$config.appDebug) {
      process.env.TAB_ID = ctx.store.state.shared.portId
      log(...args)
    }
  })

  inject('info', function (...args) {
    if (ctx.$config.appDebug) {
      process.env.TAB_ID = ctx.store.state.shared.portId
      info(...args)
    }
  })

  inject('warn', function (...args) {
    if (ctx.$config.appDebug) {
      process.env.TAB_ID = ctx.store.state.shared.portId
      warn(...args)
    }
  })

  inject('error', function (...args) {
    if (ctx.$config.appDebug) {
      process.env.TAB_ID = ctx.store.state.shared.portId
      error(...args)
    }
  })
}
