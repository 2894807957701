import { isServer } from '@/mixins/helpers'

const cancelLoadingList = [
  'support/[\\d]+'
]

function isInCancelList (url) {
  let result = false
  for (const str of cancelLoadingList) {
    if (url.match(str)) {
      result = true
      break
    }
  }
  return result
}

export default function ({ $axios, redirect, store }) {
  $axios.onRequest((config) => {
    if (
      !isServer &&
      store.state.loadingEnable === true &&
      store.state.loadingManual === false &&
      !isInCancelList(config.url)
    ) {
      store._vm.$nuxt.$loading.start()
    }
    return config
  })

  $axios.onResponse((response) => {
    if (
      !isServer &&
      store.state.loadingManual === false
    ) {
      store._vm.$nuxt.$loading.finish()
    }
    return response
  })

  $axios.onError((error) => {
    if (!isServer) {
      store._vm.$nuxt.$loading.finish()
      // store._vm.$nextTick(() => {
      // })
    }
    return Promise.reject(error)
  })
}
