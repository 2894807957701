import * as moment from 'moment'
import { isWindow } from '~/mixins/helpers'

export default (ctx, inject) => {
  inject('dateFormat', function (value, format = 'DD/MM/YYYY HH:mm') {
    return value
      ? moment(value).format(format)
      : false
  })
  inject('supportChat', async function (talkId) {
    const store = ctx.store
    if (talkId) {
      // check is talk in list
      const isTalkAtList = typeof store.state.chat.list.map(i => i.talkId).find(i => i === talkId) !== 'undefined'
      store.dispatch('setLoadingEnable', false)
      await store.dispatch('chat/load', { talkId, loadInBg: !isTalkAtList })
      if (!isTalkAtList) { // set talk as latest in list
        const talkItemObj = store.state.chat.list.find(i => i.talkId === talkId)
        if (typeof talkItemObj !== 'undefined') {
          const talkItem = JSON.parse(JSON.stringify(talkItemObj))
          talkItem.talk.last_message_sent = ctx.$dateFormat(moment(), 'YYYY-MM-DD HH:mm:ss')
          store.commit('chat/load', talkItem)
          setTimeout(() => {
            store.commit('chat/toggle', talkId)
          }, 0)
        }
      }
      store.dispatch('setLoadingEnable', true)
    } else {
      store._vm.$bvToast.toast('Talk not exists.', {
        title: 'Error',
        autoHideDelay: 5000,
        variant: 'danger'
      })
    }
  })
  // TODO: move it to store action
  inject('taskAssign', async function (taskId) {
    const store = ctx.store
    store.dispatch('setLoadingEnable', false)
    await store.dispatch('task/assign', { taskId })
    store.dispatch('setLoadingEnable', true)
    return store.state.errors && store.state.errors.length === 0
  })
  // TODO: move it to store action
  inject('taskCancelAssignment', async function (taskId) {
    const store = ctx.store
    store.dispatch('setLoadingEnable', false)
    await store.dispatch('task/cancelAssignment', { taskId })
    store.dispatch('setLoadingEnable', true)
    return store.state.errors && store.state.errors.length === 0
  })
  inject('openGlobal', function (component, id, repo) {
    const store = ctx.store
    store.dispatch('repos/chainAdd', {
      key: `${component}.${id}`,
      repo
    })
    this.$nextTick(() => {
      const lastIdx = store.getters['repos/lastIdx']
      if (lastIdx) {
        store.dispatch('repos/chainShow', lastIdx)
      }
      this.$forceUpdate()
    })
  })
  inject('loadingManual', function () {
    const store = ctx.store
    return {
      start: () => {
        store.dispatch('setLoadingManual', true)
      },
      finish: () => {
        store.dispatch('setLoadingManual', false)
      },
      state: () => {
        return store.state.loadingManual
      }
    }
  })
  inject('blinkTab', {
    timeoutId: null,
    browserTitle: '',
    faviconEl: '',
    favicon: '',
    highFavicon: '',
    msg: '',
    variant: '',
    on (msg = '', variant = 'warn') {
      if (isWindow) {
        // init step
        this.msg = msg
        this.variant = variant
        this.browserTitle = window.document.title
        this.faviconEl = document.querySelector('[rel=icon]')
        this.favicon = this.faviconEl ? this.faviconEl.href : ctx.$config.appHost + '/favicon.ico'

        switch (variant) {
          case 'warn':
            this.highFavicon = ctx.$config.appHost + '/favicon-warn.ico'
            break
          case 'alert':
            this.highFavicon = ctx.$config.appHost + '/favicon-alert.ico'
            break
        }

        window.addEventListener('focus', () => {
          this.stopBlink()
        })
        window.addEventListener('blur', () => {
          this.startBlink()
        })

        if (!window.document.hasFocus()) {
          this.startBlink(this)
        }

        // console.log('[blinkTab][ON]', this)
      }
    },
    off () {
      if (isWindow) {
        // console.log('[blinkTab][OFF]', this)
        window.removeEventListener('focus', () => {
          this.stopBlink()
        })
        window.removeEventListener('blur', () => {
          this.startBlink()
        })
        this.stopBlink()
      }
    },
    startBlink () {
      this.timeoutId = setInterval(() => {
        // console.log('[blinkTab][TICK]', this)
        if (this.msg !== '') {
          window.document.title = (window.document.title === this.browserTitle)
            ? this.browserTitle + ' - ' + this.msg
            : this.browserTitle
        }
        if (this.faviconEl) {
          this.faviconEl.href = this.faviconEl.href.match(/favicon-warn\.ico/) ? this.favicon : this.highFavicon
        }
      }, 500)
    },
    stopBlink () {
      clearInterval(this.timeoutId)
      window.document.title = this.browserTitle
      if (this.faviconEl) {
        this.faviconEl.href = this.favicon
      }
    }
  })
}
