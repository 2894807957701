import Vue from 'vue'
import DOMPurify from 'dompurify'
import { isServer } from '@/mixins/helpers'

Vue.directive('click-outside', {
  bind (el, binding, vNode) {
    el.__vueClickOutside__ = (event) => {
      if (!el.contains(event.target)) {
        // call method provided in v-click-outside value
        vNode.context[binding.expression](event)
        event.stopPropagation()
      }
    }
    document.body.addEventListener('click', el.__vueClickOutside__)
  },
  unbind (el, binding, vNode) {
  // Remove Event Listeners
    document.body.removeEventListener('click', el.__vueClickOutside__)
    el.__vueClickOutside__ = null
  }
})

Vue.directive('shtml', (el, binding) => {
  const TEMPORARY_ATTRIBUTE = 'data-temp-href-target'

  DOMPurify.addHook('beforeSanitizeAttributes', function (node) {
    if (node.tagName === 'A') {
      if (!node.hasAttribute('target')) {
        node.setAttribute('target', '_self')
      }

      if (node.hasAttribute('target')) {
        node.setAttribute(TEMPORARY_ATTRIBUTE, node.getAttribute('target'))
      }
    }
  })

  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    if (node.tagName === 'A' && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
      node.setAttribute('target', node.getAttribute(TEMPORARY_ATTRIBUTE))
      node.removeAttribute(TEMPORARY_ATTRIBUTE)
      if (node.getAttribute('target') === '_blank') {
        node.setAttribute('rel', 'noopener')
      }
    }
  })

  el.innerHTML = DOMPurify.sanitize(binding.value)
})

Vue.directive('resize', {
  inserted (el, binding) {
    const onResizeCallback = binding.value
    if (!isServer) {
      window.addEventListener('resize', () => {
        const width = document.documentElement.clientWidth
        const height = document.documentElement.clientHeight
        onResizeCallback({ width, height })
      })
    }
  }
})

// DRAGGABLE HELPERS
const ChangePositionType = {
  Start: 1,
  End: 2,
  Move: 3
}
let __assign = function () {
  __assign = Object.assign || function (t) {
    for (let s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i]
      for (const p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) {
          t[p] = s[p]
        }
      }
    }
    return t
  }
  return __assign.apply(this, arguments)
}
function extractHandle (handle) {
  // eslint-disable-next-line no-mixed-operators
  return handle && handle.$el || handle
}
function getPosWithBoundaries (elementRect, boundingRect, left, top, boundingRectMargin) {
  // eslint-disable-next-line no-void
  if (boundingRectMargin === void 0) { boundingRectMargin = {} }
  const adjustedPos = { left, top }
  const height = elementRect.height; const width = elementRect.width
  const topRect = top; const bottomRect = top + height; const leftRect = left; const rightRect = left + width
  const marginTop = boundingRectMargin.top || 0; const marginBottom = boundingRectMargin.bottom || 0; const marginLeft = boundingRectMargin.left || 0; const marginRight = boundingRectMargin.right || 0
  const topBoundary = boundingRect.top + marginTop; const bottomBoundary = boundingRect.bottom - marginBottom; const leftBoundary = boundingRect.left + marginLeft; const rightBoundary = boundingRect.right - marginRight
  if (topRect < topBoundary) {
    adjustedPos.top = topBoundary
  } else if (bottomRect > bottomBoundary) {
    adjustedPos.top = bottomBoundary - height
  }
  if (leftRect < leftBoundary) {
    adjustedPos.left = leftBoundary
  } else if (rightRect > rightBoundary) {
    adjustedPos.left = rightBoundary - width
  }
  return adjustedPos
}
const draggable = {
  bind (el, binding, vnode) {
    this.update(el, binding, vnode)
  },
  update (el, binding, vnode) {
    // Start draggable
    if (binding.value && binding.value.stopDragging) {
      return
    }
    const definedHandle = (binding.value && binding.value.handle && extractHandle(binding.value.handle))
    const handler = definedHandle || el
    if (binding && binding.value && binding.value.resetInitialPos) {
      initializeState()
      handlePositionChanged()
    }
    if (!handler.getAttribute('draggable')) {
      el.removeEventListener('mousedown', el.listener)
      handler.addEventListener('mousedown', moveStart)
      el.removeEventListener('touchstart', el.listener)
      handler.addEventListener('touchstart', moveStart, { passive: false })
      handler.setAttribute('draggable', 'true')
      el.listener = moveStart
      initializeState()
      handlePositionChanged()
    }
    if (definedHandle) {
      el.listener = undefined
      el.removeAttribute('draggable')
      definedHandle.addEventListener('mouseenter', () => {
        handler.setAttribute('draggable', 'true')
        el.listener = moveStart
      })
      definedHandle.addEventListener('mouseleave', () => {
        handler.removeAttribute('draggable')
        el.listener = undefined
      })
    }
    function emit (vnode, name, data) {
      const handlers = vnode.data.on

      // eslint-disable-next-line no-prototype-builtins
      if (handlers && handlers.hasOwnProperty(name)) {
        const handler = handlers[name]
        const fn = handler.fns || handler.fn

        if (typeof fn === 'function') {
          fn(data)
        }
      }
    }
    function move (event) {
      event.preventDefault()
      const stopDragging = binding.value && binding.value.stopDragging
      if (stopDragging) {
        return
      }
      let state = getState()
      if (!state.startDragPosition || !state.initialMousePos) {
        initializeState(event)
        state = getState()
      }
      const pos = getInitialMousePosition(event)
      const dx = pos.left - state.initialMousePos.left
      const dy = pos.top - state.initialMousePos.top
      let currentDragPosition = {
        left: state.startDragPosition.left + dx,
        top: state.startDragPosition.top + dy
      }
      const boundingRect = getBoundingRect()
      const elementRect = el.getBoundingClientRect()
      if (boundingRect && elementRect) {
        currentDragPosition = getPosWithBoundaries(elementRect, boundingRect, currentDragPosition.left, currentDragPosition.top, binding.value.boundingRectMargin)
      }
      setState({ currentDragPosition })
      updateElementStyle()
      handlePositionChanged(event)
    }
    function getBoundingRect () {
      if (!binding.value) {
        return
      }
      // eslint-disable-next-line no-mixed-operators
      return binding.value.boundingRect || binding.value.boundingElement &&
        binding.value.boundingElement.getBoundingClientRect()
    }
    function updateElementStyle () {
      const state = getState()
      if (!state.currentDragPosition) {
        return
      }
      el.style.touchAction = 'none'
      el.style.position = 'fixed'
      el.style.left = state.currentDragPosition.left + 'px'
      el.style.top = state.currentDragPosition.top + 'px'
    }
    function moveEnd (event) {
      event.preventDefault()
      document.removeEventListener('mousemove', move)
      document.removeEventListener('mouseup', moveEnd)
      document.removeEventListener('touchmove', move)
      document.removeEventListener('touchend', moveEnd)
      const currentRectPosition = getRectPosition()
      setState({
        initialMousePos: undefined,
        startDragPosition: currentRectPosition,
        currentDragPosition: currentRectPosition
      })
      handlePositionChanged(event, ChangePositionType.End)
      emit(vnode, 'moved', getState())
    }
    function moveStart (event) {
      setState({ initialMousePos: getInitialMousePosition(event) })
      handlePositionChanged(event, ChangePositionType.Start)
      document.addEventListener('mousemove', move)
      document.addEventListener('mouseup', moveEnd)
      document.addEventListener('touchmove', move)
      document.addEventListener('touchend', moveEnd)
    }
    function getInitialMousePosition (event) {
      if (event instanceof MouseEvent) {
        return {
          left: event.clientX,
          top: event.clientY
        }
      }
      if (window.TouchEvent && event instanceof TouchEvent) {
        const touch = event.changedTouches[event.changedTouches.length - 1]
        return {
          left: touch.clientX,
          top: touch.clientY
        }
      }
    }
    function getRectPosition () {
      const clientRect = el.getBoundingClientRect()
      if (!clientRect.height || !clientRect.width) {
        return
      }
      return { left: clientRect.left, top: clientRect.top }
    }
    function initializeState (event) {
      const state = getState()
      const initialRectPositionFromBinding = binding && binding.value && binding.value.initialPosition
      const initialRectPositionFromState = state.initialPosition
      const startingDragPosition = getRectPosition()
      const initialPosition = initialRectPositionFromBinding || initialRectPositionFromState || startingDragPosition
      setState({
        initialPosition,
        startDragPosition: initialPosition,
        currentDragPosition: initialPosition,
        initialMousePos: getInitialMousePosition(event)
      })
      updateElementStyle()
    }
    function setState (partialState) {
      const prevState = getState()
      const state = __assign(__assign({}, prevState), partialState)
      handler.setAttribute('draggable-state', JSON.stringify(state))
    }
    function handlePositionChanged (event, changePositionType) {
      const state = getState()
      const posDiff = { x: 0, y: 0 }
      if (state.currentDragPosition && state.startDragPosition) {
        posDiff.x = state.currentDragPosition.left - state.startDragPosition.left
        posDiff.y = state.currentDragPosition.top - state.startDragPosition.top
      }
      const currentPosition = state.currentDragPosition && __assign({}, state.currentDragPosition)
      if (changePositionType === ChangePositionType.End) {
        binding.value && binding.value.onDragEnd && state && binding.value.onDragEnd(posDiff, currentPosition, event)
      } else if (changePositionType === ChangePositionType.Start) {
        binding.value && binding.value.onDragStart && state && binding.value.onDragStart(posDiff, currentPosition, event)
      } else {
        binding.value && binding.value.onPositionChange && state && binding.value.onPositionChange(posDiff, currentPosition, event)
      }
    }
    function getState () {
      return JSON.parse(handler.getAttribute('draggable-state')) || {}
    }
  }
}
Vue.directive('draggable', {
  bind (el, binding, vnode) {
    draggable.update(el, binding, vnode)
  },
  update (el, binding, vnode) {
    draggable.update(el, binding, vnode)
  }
})
